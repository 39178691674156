<template>
  <div>

    <CPageWrapper>

      <div>
        <div class="w-[92%] max-w-[1500px] rounded lg:rounded-2xl overflow-hidden bg-stone-50 mx-auto h-[140px] sm:h-[160px] md:h-[320px] shadow shadow-emerald-900">
          <NuxtImg
                   class="contrast-125 object-cover w-full h-full"
                   loading="eager"
                   src="images/afrika/mauritius/sebastien-jermer-n7DY58YFg9E-unsplash.jpg"
                   alt="Urlaubsbild Meer und Insel mit Boot"
                   quality="40" fit="crop"
                   :modifiers="{ 'vib': '20' }"
                   sizes="sm:90vw lg:1200px"
                   width="1200" height="400" />
        </div>

        <CPageContentSection>
          <div class="mt-10 flex flex-wrap font-bold text-2xl justify-center text-center">
            <div class="sm:mr-3 mb-4 px-2 py-1 bg-[#05B0A4] text-base text-white text-center">Sicher buchen bei {{ company.name.toUpperCase() }}</div>
            <p>Die Reiseberater an deiner Seite</p>
          </div>
          <!-- <CFlowCols layout="3s">
              <CInfoBlock heading="div" title="Kostenlose Beratung">
                <p>Wir haben das perfekte Angebot mit individueller Reiseplanung für dich.</p>
              </CInfoBlock>
              <CInfoBlock heading="div" title="Persönliche Angebote">
                <p>Von abgelegenen Stränden und exotischen Inseln bis hin zu malerischen Bergdörfern und aufregenden Metropolen.</p>
              </CInfoBlock>
              <CInfoBlock heading="div" title="Am Puls der Zeit">
                <p>Profitiere von unserer Erfahrung und unserem umfangreichen Know How.</p>
              </CInfoBlock>
            </CFlowCols> -->
        </CPageContentSection>
      </div>

      <CPartnerAmadeus :params="query" ibe="package" />

      <CPageContentSection>
        <CInfoline class="mt-6 mb-4" no-spacing>Nützliche Tipps</CInfoline>
        <h2 class="mb-10 font-sansScript text-3xl sm:text-4xl text-center text-lux-700">Pauschalreisen - Einfach ein gutes Gefühl</h2>
        <CFlowCols no-spacing>
          <CInfoItem title="Wie kann ich meine Flugzeit verkürzen?">
            <p>Für mehr Komfort wähle Direktflüge.</p>
            <p>Mehr Reisetipps unter: <NuxtLink href="/service/haeufige-fragen" class="font-bold" aria-label="Häufig gestellte Fragen (FAQ)">Häufig gestellte Fragen (FAQ)</NuxtLink>
            </p>
          </CInfoItem>
          <CInfoItem title="Wie bekomme ich die besten Hotels angezeigt?">
            <p>Für die besten Hotels erhöhe die Anzahl der Sterne unter Hotelkategorie und du kannst noch zusätzlich Hotelbewertungen mit einbeziehen.</p>
          </CInfoItem>
          <CInfoItem title="Bist du flexibel bei deinen Reisedaten?">
            <p>Wenn du flexibel bist, kannst du oft bessere Flüge und Unterkünfte finden.</p>
          </CInfoItem>
          <CInfoItem title="Benötigst du eine Reiserücktrittsversicherung?">
            <p>Eine Reiserücktrittsversicherung schützt dich vor finanziellen Verlusten, wenn du deine Reise stornieren musst. Wir bieten dir eine große Auswahl an Reiseversicherungen, so dass du die für dich passende Versicherung finden kannst. Wir beraten dich gerne und beantworten all deine Fragen.</p>
            <p>
              <NuxtLink class="font-bold" href="/service/reiseversicherungen/reiseschutz-von-ergo" aria-label="Reiseversicherungen">Reiseversicherungen entdecken</NuxtLink>
            </p>
          </CInfoItem>
        </CFlowCols>
      </CPageContentSection>

    </CPageWrapper>

  </div>
</template>

<script setup lang="ts">
import pauschalJson from '~/assets/json/pauschal.json'
import specialsJson from '~/assets/json/specials.json'
import hotelOfferJson from '~/assets/json/hotel.offers.json'

interface ParamsType {
  key: string,
  name: string,
  query: string,
}

const { company } = useAppConfig()
const route = useRoute()
const metaProps = route.meta
const pathType = metaProps.pathType as string
const paramSets: Record<string, ParamsType[]> = {
  'pauschal': pauschalJson,
  'hotels': hotelOfferJson,
  'specials': specialsJson,
}

const pathToMatch = route.path.endsWith('/') ? route.path.substring(0, route.path.length - 1) : route.path
const params: ParamsType = (paramSets[pathType]).filter((x: ParamsType) => x.key === pathToMatch)[0]
const query = metaProps.query ? metaProps.query as string : params.query

// const titleText = query.includes('aid') ? 'Die besten Angebote' : 'Die beliebtesten Hotels'
// const metaTitle = `Urlaub ${params.name}`.length < 50 ? `Urlaub ${params.name} buchen • ${company.name}` : `Urlaub ${params.name}`
// const metaTitle = `Urlaub ${params.name}`.length < 50 ? `Urlaub ${params.name} buchen • ${company.name}` : `Urlaub ${params.name}`
const metaTitle = `Urlaub ${params.name}`

const seoDescription =
  params.name + ' Angebote✅ ' +
  params.name + ' Last Minute✅ ' +
  params.name + ' Urlaub✅ ' +
  params.name + ' All-Inclusive✅ '

useSeoMeta({
  title: metaTitle,
  description: seoDescription,
  robots: { index: false, follow: false, noarchive: true, nositelinkssearchbox: true, nosnippet: true, indexifembedded: false, noimageindex: true },
})
</script>
